.app {
  $border-radius: 4px;
  $border: 1px solid #ccc;

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #0d1222;

  .card {
    border-radius: $border-radius;
    max-width: 480px;
    padding: 20px;
    background-color: #fff;

    > * {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .camera-container {
    position: relative;

    > video {
      display: none;
    }

    > canvas {
      border-radius: $border-radius;
      max-width: 480px;
      width: 100%;
    }

    > .flip-button {
      background: transparent;
      border: 0;
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.4);
      width: 48px;
      height: 40px;
      border-radius: 4px;
    }
  }

  select.video-devices-select {
    width: 100%;
    font-size: 1em;
    height: 40px;
    border-radius: $border-radius;
    padding: 0 14px;
    appearance: none;
    border: $border;
  }

  .result-container {
    border: $border;
    border-radius: $border-radius;
    min-height: 60px;

    > div {
      border-bottom: $border;
      margin: 0 8px;
      padding: 8px 8px;
      word-break: break-all;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    .licenses {
      font-size: 0.7em;
    }
  }

  iframe.detail-information {
    display: block;
    border: $border;
    width: 100%;
    border-radius: $border-radius;
  }
}
